import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import config from "../../config";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Loadable from "@loadable/component";
import Img from "gatsby-image";

import Header from "../components/Header";
import BookVisit from "../components/BookVisit";
import SEO from "../components/Seo";
import Prices from "../components/Prices";
import SwitchButton from "../components/SwitchButton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons/faCertificate";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons/faDrawPolygon";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons/faUserGraduate";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons/faMoneyBillWave";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";

library.add(
  ...[
    faCertificate,
    faCheck,
    faChartLine,
    faDrawPolygon,
    faUserGraduate,
    faMoneyBillWave,
    faHandHoldingUsd,
  ]
);
const onlinePros = [
  {
    icon: <FontAwesomeIcon icon={"draw-polygon"} className="iconBlock" />,
    title: "Flexibilidade",
    desc: (
      <>
        <span className="destak">71% </span> dos alunos acreditam que cursos
        online aumenta a flexibilidade para fazer as aulas
      </>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={"check"} className="iconBlock" />,
    title: "Standard",
    desc: (
      <>
        <span className="destak">49% </span> dos estudantes no mundo inteiro
        fizeram um curso online nos últimos anos
      </>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={"chart-line"} className="iconBlock" />,
    title: "Crescimento exponêncial",
    desc: (
      <>
        O Ensino online corporativo{" "}
        <span className="destak">aumentou 900% </span>nos últimos 16 anos
      </>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={"user-graduate"} className="iconBlock" />,
    title: "Alunos / trabalhadores",
    desc: (
      <>
        Mais de <span className="destak">76% </span>dos alunos de cursos online
        estuda e trabalha
      </>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={"money-bill-wave"} className="iconBlock" />,
    title: "Cursos pagos",
    desc: (
      <>
        Mais de <span className="destak"> 91% </span> dos alunos de cursos
        online estuda em instituições privadas
      </>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={"hand-holding-usd"} className="iconBlock" />,
    title: "Mercado rentável",
    desc: (
      <>
        A previsão é que até 2020 o ensino online se torne num mercado de{" "}
        <span className="destak">37,6 Mil milhões de dollars </span>
      </>
    ),
  },
];
const trainingRoom = [
  "Sala de formação mobilada de acordo com o evento*",
  "Sala de Espera",
  "Internet",
  "Entrada com código",
];
const mktFeatures = [
  "posts por semana no Facebook e Instagram, linkedin, twitter ou Google My Business",
  "Criação das imagens para os posts",
  "Reunião mensal para definir estratégia e calendário editorial",
  "Calendário de publicações",
];
const warranty = (
  <>
    <FontAwesomeIcon icon={"certificate"} className="iconBlock" />
    <h3>7 dias de garantia</h3>
    <h4>
      Queremos que fique feliz! Terá garantia de satisfação até 7 dias após a
      entrega.
    </h4>
  </>
);
const portfolio = (data) => (
  <>
    <div className="row justify-content-md-center no-gutters">
            <div className={"col pt-5"}>
                  <h3>Sabia que o website da EMMIM® foi elaborado por nós?</h3>
                  <h4>
                    Conheça alguns dos trabalhos que já elaborámos além do nosso.
                  </h4>
            </div>
    </div>
    <div className="row justify-content-md-center no-gutters">
            <div className={"col p-5"} style={{ cursor: "pointer" }}>
              <a href="https://www.anaemilia.pt" target="_blank">
                <Img className={"image img-fluid"} fluid={data.anaemilia.childImageSharp.fluid} />
                <p>Feito à medida</p>
              </a>
            </div>
            <div className={"col pt-5"} style={{ cursor: "pointer" }}>
              <a href = "https://www.equilibriodeluzbyfemida.pt/" target="_blank">
                <Img className={"image img-fluid"} fluid={data.femida.childImageSharp.fluid} />
                <p>(Plano Startup)</p>
              </a>
            </div>
            <div className={"col p-5"} style={{ cursor: "pointer" }}>
              <a href = "https://www.florbelacastilhomanso.pt/"
              target = "_blank" >
                <Img className={"image img-fluid"} fluid={data.florbela.childImageSharp.fluid} />
                <p>(Plano Startup)</p>
                <p>* Logo feito pela EMMIM®</p>
              </a>  
            </div>
            <div className={"col pt-5"} style={{ cursor: "pointer" }}>
              <a href = "https://www.sandrapedro.pt/"
              target = "_blank" >
                <Img className={"image img-fluid"} fluid={data.sandrapedro.childImageSharp.fluid} />
                <p>(Plano Startup)</p>
                <p>* Logo feito pela EMMIM®</p>
              </a>  
            </div>
    </div>
  </>
);
const siteFeatures = [
  "Email personalizado",
  "Domínio personalizado .com ou .pt",
  "1 Língua",
  "Site Responsivo (adapta-se no telemóvel/computador)",
  "Revisão e optimização do conteúdo",
  "consultoria de imagem",
  "Apresentação",
  "Apresentação dos serviços",
  "Galeria de imagens",
  "Marcação de consultas online",
  "integração com redes sociais",
  "localização",
  "Contactos",
];
const siteProFeatures = [
  ...siteFeatures,
  "Vários formulários de contacto",
  "Testemunhos",
  "Relatório e estatísticas mensal",
];
const packagesFeatures = [
  "Acesso a parcerias estratégicas",
  "Sala de Espera",
  "Internet",
  "Utilização da copa e zona de lounge exterior",
  "Utilização do balneário",
  "Possibilidade de cacifo",
  "Utilizável em dias úteis"
];
const featuresSimple = [
  ...packagesFeatures,
  "Presença no nosso site com possibilidade de agendamento de consultas online de acordo com a sua disponibilidade",
  "Apresentação nos nossos canais de divulgação",
  //"5% desconto na aquisição de horas",
  //"10% de desconto em serviços de Marketing e divulgação",
];
const featuresPro = [
  ...packagesFeatures.filter((f) => "Possibilidade de cacifo" != f),
  "Cacifo",
  "Presença no nosso site com possibilidade de agendamento de consultas online de acordo com a sua disponibilidade",
  "Apresentação nos nossos canais de divulgação",
 // "5% desconto na aquisição de horas",
 // "15% de desconto em serviços de Marketing e divulgação",
  "Recepção de pagamentos",
  "confirmação de consultas",
  "20 impressões",
  "Divulgação dos seus serviços no nosso espaço",
  
];
const featuresPremium = [
  ...featuresPro.map((v) =>
    typeof v === "string" && v.indexOf("serviços de Marketing") > -1
      ? "20% de desconto em serviços de Marketing e divulgação"
      : v?v:""
  ).filter(e=>e),
  "Emissão de recibos",
  "10 Cafés por Mês",
  "",
  "",
  "",
  ""
];

const titles = {
	salasformacao: (
		<>
			<p>
				A Sala wonder tem 37m<sup>2</sup> de área, com capacidade para 30
				formandos em disposição tipo plateia.
			</p>
			<p>
				É a sala ideal para aulas de grupo, Yoga ou meditação ou formações que
				necessitem de marquesas para a prática
			</p>
		</>
	),
	salasmanha: (
		<>
			Uma manhã todas as semanas <br />
			(8h às 13h)
			<br />
			10% de desconto durante fim-de-semana
		</>
	),
	salastarde: (
		<>
			Uma tarde ou noite todas as semanas <br />
			(13h às 18h ou das 18h às 23h)
			<br />
			10% de desconto durante fim-de-semana
		</>
	),
	salasdiaCompleto: (
		<>
			Um dia todas as semanas <br />
			(8h às 23h)
			<br />
			10% de desconto durante fim-de-semana
		</>
	),
	salashoras: (
		<p>
			6 meses de validade após a aquisição <br />
			(Apenas podem ser utilizados em<span class="destak"> dias úteis</span>)
		</p>
	),
	marketingsm: (
		<>
			<p>Como atrair mais pacientes para as suas consultas?</p>
			<p>"Esperança não é uma estratégia de marketing"</p>
		</>
	),
	marketingsite:
		"Evite gastar dinheiro à toa, perder tempo a aprender a programar ou ganhar cabelos brancos pelo stress de não conseguir ter um site com aspecto profissional e moderno",
};
const productTitles = {
  salas: (
    <>
      Precisa de algo <span className="destak">flexível</span> ou os seus
      atendimentos são maioritariamente num dado{" "}
      <span className="destak">período</span>?
      
    </>
  ),
  servicos: (
    <>
      Foque-se exclusivamente <span className="destak">na sua arte</span> e
      deixe para nós tudo o resto.
    </>
  ),
  marketing: (
    <>
      <h3 className="pb-4">
        Quanto pagaria para ter um site e email personalizados, desenhados e
        mantidos por especialistas, marcação de consultas online e gestão das
        redes sociais?
      </h3>
      <span className="destak">Parece-lhe um sonho? </span>
      <h3 className="p-4">
        Não sei se sabe, mas estes serviços podem custar várias{" "}
        <span className="destak">centenas</span> de euros por mês.
      </h3>
      <h3>
        Seja bem vindo à era digital, soluções chave na mão, de marketing e
        divulgação,{" "}
        <span className="destak">especifícas para terapeutas</span>.
      </h3>
    </>
  )
  
};
const prices = {
  salasmanha: [
    {
      title: "Premium",
      price: {
        amt: 166.37,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
      },
      description: "Consultório privado\\n  8h às 13h",
      features: ["Pelo menos 20h/mês", ...featuresPremium],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },

    {
      title: "Professional",
      price: {
        amt: 142.87,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
      },
      description: "Consultório privado\\n  8h às 13h",
      features: ["Pelo menos 20h/mês", ...featuresPro],
      footerNote:
        "",
      ctaLabel: "Escolha o seu consultório",
    },
    {
      title: "Startup",
      subtitle: "",
      price: {
        amt: 119.37,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
      },
      //originalPrice: 67,
      description: "Consultório privado\\n  8h às 13h",
      features: ["Pelo menos 20h/mês", ...featuresSimple],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },
  ],
  salastarde: [
    {
      title: "Premium",
      price: {
        amt: 207.27,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
        startingAt: true,
      },
      description: "Consultório privado\\n  13h às 18h ou das 18h às 23h",
      features: ["Pelo menos 20h/mês", ...featuresPremium],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },

    {
      title: "Professional",
      price: {
        amt: 181.37,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
        startingAt: true,
      },
      description: "Consultório privado\\n  13h às 18h ou das 18h às 23h",
      features: ["Pelo menos 20h/mês", ...featuresPro],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },
    {
      title: "Startup",
      price: {
        amt: 155.47,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 20,
        startingAt: true,
      },
      description: "Consultório privado\\n  13h às 18h ou das 18h às 23h",
      features: ["Pelo menos 20h/mês", ...featuresSimple],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
      subtitle: "  ",
    },
  ],
  salasformacao: [
    {
      title: "Fim-de-semana completo",
      price: {
        amt: 216.7,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Sábado e Domingo\\n 9h-18h",
      features: [...trainingRoom],
      ctaLabel: "",
      footerNote: (
        <>
          <p className="pt-4">
            * Material incluído: Tela de projecção, Flipchart, Extensões,
            Datashow e videoprojector
          </p>
        </>
      ),
    },
    {
      title: "Dia Completo",
      subtitle: "Dia completo durante o fim-de-semana",
      price: {
        amt: 139.7,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Dia completo \\n 9h-18h",
      features: [...trainingRoom],
      ctaLabel: "",
      footerNote: (
        <>
          <p className="pt-4">
            * Material incluído: Tela de projecção, Flipchart, Extensões,
            Datashow e videoprojector
          </p>
        </>
      ),
    },

    {
      title: "Meio dia",
      subtitle: "Meio dia durante o fim-de-semana",
      price: {
        amt: 84.7,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Meio dia (manhã/tarde) \\n 9h-13h ou 14h-18h",
      features: [...trainingRoom],
      ctaLabel: "",
      footerNote: (
        <>
          <p className="pt-5">
            * Material incluído: Tela de projecção, Flipchart, Extensões,
            Datashow e videoprojector
          </p>
        </>
      ),
    },
  ],

  salasdiaCompleto: [
    {
      title: "Premium",
      price: {
        amt: 341.07,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 60,
        startingAt: true,
      },
      description: "Consultório privado\\n  8h às 23h",
      features: ["Pelo menos 60h/mês", ...featuresPremium],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },

    {
      title: "Professional",
      price: {
        amt: 317.57,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 60,
        startingAt: true,
      },
      description: "Consultório privado\\n  8h às 23h",
      features: ["Pelo menos 60h/mês", ...featuresPro],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },
    {
      title: "Startup",
      subtitle: "  ",
      price: {
        amt: 294.00,
        ccy: "EUR",
        vat: "23%",
        freq: "mês",
        hours: 60,
        startingAt: true,
      },
      description: "Consultório privado\\n  8h às 23h",
      features: ["Pelo menos 60h/mês", ...featuresSimple],
      ctaLabel: "Escolha o seu consultório",
      footerNote:
        "",
    },
  ],
  salashoras: [
    {
      title: "Pack 35 Horas",
      price: {
        amt: 372.87,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Consultório privado \\n35 horas",
      features: ["1 ano de validade","35h num consultório", ...packagesFeatures],
      ctaLabel: "Quero Ter",
    },
    {
      title: "Pack 20 Horas",
      price: {
        amt: 231.7,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Consultório privado \\n20 horas",
      features: ["6 meses de validade", "20h num consultório", ...packagesFeatures],
      ctaLabel: "Quero Ter",
    },

    {
      title: "Pack 10 Horas",
      price: {
        amt: 125.87,
        ccy: "EUR",
        vat: "23%",
      },
      description: "Consultório privado \\n10 horas",
      features: ["6 meses de validade", "10h num consultório", ...packagesFeatures],
      ctaLabel: "Quero Ter",
    },
    {
      title: "Pack 1 Hora",
      price: {
        amt: 14.17,
        ccy: "EUR",
        vat: "23%",
      },
      subtitle: " ",
      description: "Consultório privado \\n1 hora",
      features: ["6 meses de validade", "1h num consultório", ...packagesFeatures],
      ctaLabel: "Quero Ter",
    },
  ],
  marketingsite: [
    {
      title: "premium",

      price: {
        amt: 80.67,
        freq: "mês",
      },
      
      description:
        "Pensado ao detalhe com um único objectivo: Presença digital com imagem premium!",
      features: ["Até 10 páginas", ...siteProFeatures],
      ctaLabel: "Quero Ter",
    },
    {
      title: "Professional",
      price: {
        amt: 69.67,
        freq: "mês",
      },
      description:
        "Pensado ao detalhe com um único objectivo: Presença digital com imagem profissional!",
      features: ["Até 5 páginas", ...siteProFeatures],
      ctaLabel: "Quero Ter",
    },
    {
      title: "startup",
      price: {
        amt: 50.27,
        freq: "mês",
      },
      description:
        "Oportunidade para ter o seu próprio site por menos que um café e um pastel de nata por dia!",
      features: [
        "Uma única página",
        ...siteFeatures,
        "1 formulário de contacto",
      ],

      cta: (
        <a
          target="_blank"
          href="https://mkt.emmim.pt/"
          className="btn btn-cta"
        >
          {" "}
          Saber mais
        </a>
      ),
    },
  ],
  marketingsm: [
    {
      title: "Sob consulta",

      description: <>Envie-nos um e-mail <a href='mailto:geral@emmim.pt'>geral@emmim.pt</a> para mais informações</>,
      features: [],
      footerNote: "",
      ctaLabel: "Quero Ter",
    }
  ],
  marketingdivulgacao: [
    {
      title: "Marcação de consultas",
      price: {
        amt: 19.97,
        freq: "mês",
      },
      subtitle: "",
      description:
        "Presença no site da EMMIM® com possibilidade de agendamento de consultas online de acordo com a sua disponibilidade",
      features: [
        "Presença no site da EMMIM®",
        "Possibilidade de agendamento de consultas online",
        "1 artigo por mês no blog da EMMIM®",
      ],
      ctaLabel: "Quero Ter",
    },
   /* {
      title: "Criação de redes sociais",
      price: {
        amt: 47,
      },
      subtitle: "",
      description:
        "Ainda não tem presença nas redes sociais? Criamos os seus perfís profissionais e personalizados",
      features: ["facebook", "instagram", "Google Business", "Linkedin"],
      ctaLabel: "Quero Ter",
    },*/

    {
      title: "Cartões de Visita",
      price: {
        amt: 50.27,
        vat: "23%",
      },
      subtitle: "",
      description: "Criação e impressão do seu cartão de visita",
      features: [
        "250 cartões de visita",
        "design criativo",
        "cartão personalizado",
      ],
      ctaLabel: "Quero Ter",
    },
    // {
    //   title: "Logotípo",
    //   price: {
    //     amt: 50.27,
    //     vat: "23%",
    //   },
    //   subtitle: "",
    //   description: "Criação de um logo à sua medida",
    //   features: [
    //     "Logo personalizado",
    //     "design criativo",
    //     "3 revisões incluídas",
    //   ],
    //   ctaLabel: "Quero Ter",
    // },
  ],
  servicos: [
    {
      title: "Recepção de pagamentos",
      price: {
        amt: 19.97,
        vat: "23%",
        freq: "mês",
      },
      description:
        "Quantas vezes atrasou(ainda mais) a consulta seguinte por causa de um pagamento mais demorado? Utilize o seu precioso tempo para se dedicar em exclusivo ao seu paciente",
      subtitle: "",
      features: [
        "Recepção de pagamentos em dinheiro",
        "Recepção de pagamentos em multibanco (com o seu terminal)",
      ],
      ctaLabel: "Quero Ter",
    },

    {
      title: "Emissão de facturas",
      price: {
        amt: 19.97,
        vat: "23%",
        freq: "mês",
      },
      description:
        "Acabe com o pânico de emitir facturas e deixe-nos ajudar com essa tarefa",
      subtitle: "",
      features: [
        "Emissão de recibos/facturas através da sua plataforma de facturação",
      ],
      ctaLabel: "Quero Ter",
    },

    {
      title: "Gestão de Agenda",
      price: {
        amt: 19.97,
        vat: "23%",
        freq: "mês",
      },
      subtitle: "",
      description:
        "Poupe o seu tempo de (re)agendar consultas e aumente o número de presenças confirmando que os seus pacientes irão estar presentes",
      features: [
        "Marcação de consultas",
        "Gestão da agenda de pacientes",
        "Confirmação das consultas",
      ],
      ctaLabel: "Quero Ter",
    },
  ],
  cursosOnline: [],
};
const types = [
  { type: "salas", label: "consultórios" },
  { type: "servicos", label: "serviços de apoio" },
  { type: "marketing", label: "Marketing e divulgação" }
];
const packTypes = {
  salas: [
    { type: "horas", label: "flexível" },
    { type: "manha", label: "Manhã" },
    { type: "tarde", label: "Tarde ou Noite" },
    { type: "diaCompleto", label: "Dia Completo" }
  ],
  marketing: [
    // { type: "site", label: "Website e Email" },
    // { type: "sm", label: "Gestão Redes Sociais" },
    { type: "divulgacao", label: "Divulgação" },
  ],
};
const Footer = Loadable(() => import("../components/Footer"));
const SocialLinks = Loadable(() => import("../components/SocialLinks"));

const PricesPage = ({ data, ...props }) => {
  let defaultType =
    typeof window === "undefined"
      ? "salas"
      : typeof window.location !== "undefined" && window.location.hash
      ? window.location.hash.replace("#", "")
      : types[0].type;
  let type =
    (types.find((f) => f.type === defaultType) && defaultType) ||
    Object.keys(packTypes).find((p) =>
      packTypes[p].filter((v) => v.type === defaultType)
    );
  let pack =
    packTypes[type] && packTypes[type].find((f) => f.type === defaultType)
      ? defaultType
      : (packTypes[type] && packTypes[type][0].type) || "";
  const [selectedType, setSelectedType] = useState(type);
  const [selectedPack, setSelectedPack] = useState(pack);
  const setPackageHandler = (newPackage, root) => {
    if (typeof window !== "undefined") {
      window.location.hash = newPackage || root;
    }
    setSelectedPack(newPackage);
  };
  return (
    <Layout>
      <SEO
        description="Sem surpresas, simplesmente um preço justo e honesto.\n Escolha o plano que melhor se adapta às suas necessidades."
        title={"os nossos planos"}
        site={`${config.url}${config.pathPrefix}precos`}
      />
      <Header
        showHeader={false}
        title="os nossos planos"
        smallSubtitle="Sem surpresas, simplesmente um preço justo e honesto.\n Escolha o plano que melhor se adapta às suas necessidades."
        frontPage={false}
        active="precos"
      />
      <div className="gray-100-bg content noGutters pt-5">
        <div className="container-fluid bg-fg-withLogo fg-bg pt-5">
          <div className="row pt-5  justify-content-md-center text-center">
            <div className="col-sm-12 col-md-10 p-5">
              <h1 className="pt-5">Os nossos planos</h1>
              <h5>
                Sem surpresas, simplesmente{" "}
                <span className="destak">um preço justo e honesto.</span>{" "}
                Escolha o plano que melhor se adapta às suas necessidades.
              </h5>
            </div>
          </div>
        </div>
        <div className="content container  text-center" style={{maxWidth: "1370px !important"}}>
          <div className="row">
            <SwitchButton
              buttons={types}
              action={(v) => {
                setSelectedType(v);
                setPackageHandler(packTypes[v] ? packTypes[v][0].type : "", v);
              }}
              selectedValue={selectedType}
            />
          </div>
          {productTitles[selectedType] && (
            <div className="row justify-content-md-center no-gutters p-5">
              <h3 className="font-primary">{productTitles[selectedType]}</h3>
            </div>
          )}
          <div className="row justify-content-md-center no-gutters">
            <div className="col-sm-10 text-center">
              <Fade bottom distance="100px">
                <>
                  {packTypes[selectedType] && (
                    <SwitchButton
                      buttons={packTypes[selectedType]}
                      action={setPackageHandler}
                      selectedValue={selectedPack}
                    />
                  )}
                </>
              </Fade>
            </div>
          </div>
          {titles[selectedType + selectedPack] && (
            <Fade bottom distance="100px">
              <h3 className="pt-5">{titles[selectedType + selectedPack]}</h3>
            </Fade>
          )}
          <div className="row justify-content-md-center no-gutters">
            <div className={"packagesContainer"}>
              <div className="container-fluid">
                <div className="row">
                  {prices[selectedType + selectedPack].map((p, idx) => (
                    <Prices
                      key={idx}
                      {...p}
                      numberOfCards={prices[selectedType + selectedPack].length}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center no-gutters">
            <div className={"col"}>
              {"marketingsite" === selectedType + selectedPack && warranty}
            </div>
          </div>
          
          {"marketingsite" === selectedType + selectedPack && portfolio(data)}
            
          {selectedType !== "cursosOnline" && (
            <div className="row pt-5 justify-content-md-center no-gutters">
              <div className="col-sm-10 text-center">
                <h3 className="pt-5 text-center">
                  Não encontra o que procura?
                </h3>
                <h2 className="p-2 text-center">
                  Conte-nos como podemos ajudar
                </h2>
                <h3 className="pt-1 text-center">
                  <a href="mailto:geral@emmim.pt">geral@emmim.pt</a>
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
      <BookVisit bg="calendar prices pt-0" />
      <SocialLinks />
      <Footer />
    </Layout>
  );
};

export const query = graphql `
  query PricesImages {
       anaemilia: file(relativePath: {
         eq: "anaemilia.jpg"
       }) {
         childImageSharp {
           fluid {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
       }

        femida: file(relativePath: {
          eq: "femida.jpg"
        }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

         florbela: file(relativePath: {
           eq: "florbela.jpg"
         }) {
           childImageSharp {
             fluid {
               ...GatsbyImageSharpFluid_withWebp
             }
           }
         }

          sandrapedro: file(relativePath: {
            eq: "sandrapedro.jpg"
          }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
   

    
  }
`;

export default PricesPage;
