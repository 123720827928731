import React from "react";
import "../assets/sass/_prices.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import Fade from "react-reveal/Fade";

library.add(...[faCheck]);


const Price = ({
  header,
  title,
  subtitle,
  price,
  originalPrice,
  description,
  features,
  footerNote,
  cta,
  action = () => {},
  numberOfCards = 1
}) => {
  return (
    <div className={`package col fadein`}>
      <div className="header">
        {header && <span className="header">{header}</span>}
        <span className="title">
          {title.split("\\n").map((v, idx) => (
            <div key={idx}>{v}</div>
          ))}
        </span>
        <span className="subtitle text-secondary">{subtitle}</span>
      </div>
      <div className="content">
        {price && <Fade bottom>
          <span className="price">
            {originalPrice && (
              <span className="origPrice">{originalPrice}</span>
            )}
            <span className="ccy">{/*price.ccy*/}</span>
            {price?.amt}
            {price?.startingAt && (
              <span className="priceCaption">*{"desde"}</span>
            )}
            {price?.freq && <span className="freq">/{price.freq}</span>}
            {price?.vat && <span className="vat"> +{"iva"}</span>}
          </span>
          <>
            {price?.hours && (
              <div className="priceHour">
                ({Math.round((price.amt / price.hours) * 100) / 100} por hora)
              </div>
            )}
          </>
        </Fade>}

        {description && (
          <Fade bottom>
             {typeof description === "string"
                  ?  <h4 className="desc">
                      {description.split("\\n").map((v, idx) => (
                        <div key={idx}>{v}</div>
                      ))}
                    </h4> : description}
           
          </Fade>
        )}

        {features && (
          <Fade bottom>
            <>
              {features.map((d, i) => {
                return (
                  <span className="features" key={`desc-${i}`}>
                    {d && <FontAwesomeIcon icon={"check"} />}
                    <span className="text"> {d}</span>
                  </span>
                );
              })}
            </>
          </Fade>
        )}
        {cta}
        {footerNote && (
          <Fade bottom>
            <h6 className="footerNote desc">
              <b>
                {typeof footerNote === "string"
                  ? footerNote.split("\\n").map((v, idx) => (
                      <div key={idx}>
                        {idx === 0 ? "* " : ""}
                        {v}
                      </div>
                    ))
                  : footerNote}
              </b>
            </h6>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default Price;
