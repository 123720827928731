import React from "react";
import "../assets/sass/_switchButton.scss";


const SwitchButton = ({
  buttons = [],
  action = () => {},
  selectedValue = "",
}) => {
  return (
    <div className={"switchButtonContainer"}>
      {buttons.map((prop, idx) => {
        const { type, label } = prop;
        return (
          <button
            style={{ width: `${(100 / buttons.length)}%` }}
            key={`button${idx}`}
            className={`${"item"} ${selectedValue === type ? "active" : ""}`}
            onClick={(e) => action(type)}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};

export default SwitchButton;
